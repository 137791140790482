/* eslint-disable react/no-children-prop */
// eslint-disable-next-line no-use-before-define
import React from 'react'
import { a } from '@react-spring/web'

const Overlay: React.FunctionComponent<any> = ({ fill }) => {
  return (
    <div className="overlay">
      <a.svg viewBox="0 0 583 720" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={10.5} fontWeight={500} letterSpacing="0em">
          <tspan x={40} y={175.318} children="Mykhailo" />
          <tspan x={40} y={188.318} children="Marynenko" />
        </text>
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={52} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={257.909} children={'Get to know me closer'} />
        </text>
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={12} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={270.909} />
        </text>
        <text style={{ whiteSpace: 'pre' }} fill="#E8B059" fontFamily="Inter" fontSize={48} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={321.909} children="on GitHub" />
        </text>
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={10.5} fontWeight={500} letterSpacing="0em">
          <tspan x={40} y={640.318} children="Double-click on the sphere to open socials" />
        </text>
      </a.svg>
    </div>
  )
}

export default Overlay
